import { useInfiniteQuery } from '@tanstack/react-query';
import { useAxios } from './use-axios';
import { loadMediaSectionWithShow } from '../api/pac/load-media-section-with-show';
import { queryKeys } from '../config/use-query';
import { useVideoConfig } from '../config/VideoAppContext';
export const useMediaSectionWithShowPaging = (id, initialData) => {
  const {
    usePreview,
    previewDate
  } = useVideoConfig();
  const axios = useAxios();
  const {
    isPending,
    isError,
    data: pagedDataGroups,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: [queryKeys.MediaSectionWithShow(id), {
      usePreview,
      previewDate
    }, 'paged', axios],
    initialPageParam: '',
    queryFn: next => loadMediaSectionWithShow(axios, id, usePreview, previewDate, next.pageParam),
    getNextPageParam: ({
      next
    }) => next || undefined,
    initialData: initialData ? {
      pages: [initialData],
      pageParams: []
    } : undefined
  });
  const list = pagedDataGroups?.pages?.reduce((acc, data) => {
    const medias = [...acc.medias, ...data.medias];
    return {
      ...acc,
      medias
    };
  });
  return {
    error: isError,
    isLoading: isPending,
    data: list,
    loadMore: fetchNextPage,
    canLoadMore: !!hasNextPage
  };
};