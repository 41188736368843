import { createRequestUrl } from '../../../../play-core/utils/request-url-helper';
export const MediaSectionWithShowApiPath = '/media-section-with-show';
export const loadMediaSectionWithShow = async (axios, sectionId, preview, previewDate, next) => {
  const requestUrl = createRequestUrl(MediaSectionWithShowApiPath, {
    sectionId,
    preview,
    previewDate,
    next
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};